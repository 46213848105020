<template>

    <div class="app-container menus">
        <el-form :model="queryParams" ref="queryForm" v-hasPermi="['system:dept:list']" :label-position="labelPosition" :inline="true" v-show="showSearch" style="width: 100%;height: 0.5%;text-align: left;">
            <el-form-item label="部门名称" prop="deptName">
                <el-input
                        v-model="queryParams.deptName"
                        placeholder="请输入部门名称"
                        clearable
                        size="small"
                        @keyup.enter.native="handleQuery"
                />
            </el-form-item>
            <el-form-item label="状态" prop="status">
                <el-select v-model="queryParams.status" placeholder="部门状态" clearable size="small">
                    <el-option
                            v-for="item in options"
                            :key="item.id"
                            :label="item.value"
                            :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery" v-hasPermi="['system:dept:list']">搜索</el-button>
                <el-button icon="el-icon-refresh" size="mini" @click="resetQuery" v-hasPermi="['system:dept:list']">重置</el-button>
            </el-form-item>
        </el-form>

        <el-row :gutter="10" class="mb8">
            <el-col :span="1.5">
                <el-button
                        type="primary"
                        plain
                        icon="el-icon-plus"
                        size="mini"
                        @click="handleAdd"
                         v-hasPermi="['system:dept:add']"
                >新增</el-button>
            </el-col>
            <el-col :span="1.5">
                <el-button
                        type="info"
                        plain
                        icon="el-icon-sort"
                        size="mini"
                        @click="toggleExpandAll"
                >展开/折叠</el-button>
            </el-col>
<!--            <right-toolbar :showSearch.sync="showSearch" @queryTable="getList"></right-toolbar>-->
        </el-row>

        <el-table
                v-if="refreshTable"
                :data="deptList"
                style="width: 100%;margin-bottom: 20px;"
                row-key="deptId"
                :default-expand-all ="isExpandAll"
                :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
            <el-table-column
                    prop="deptName"
                    label="部门名称"
                    width="180">
            </el-table-column>
            <el-table-column
                    prop="orderNum"
                    label="排序"
                    width="180">
            </el-table-column>
            <el-table-column
                    prop="status"
                    label="状态"
                    width="180">
                <template slot-scope="scope">
                    <el-tag type="primary"  plain v-if="scope.row.status === 0" >正常</el-tag>
                    <el-tag type="danger"  plain v-else>停用</el-tag>
                </template>
            </el-table-column>
            <el-table-column
                    prop="createTime"
                    label="创建时间"
                    width="180">
            </el-table-column>
            <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
                <template slot-scope="scope">
                    <el-button
                            size="mini"
                            type="text"
                            icon="el-icon-edit"
                            @click="handleUpdate(scope.row)"
                            v-hasPermi="['system:dept:edit']"
                    >修改</el-button>
                    <el-button
                            size="mini"
                            type="text"
                            icon="el-icon-plus"
                            @click="handleAdd(scope.row)"
                            v-hasPermi="['system:dept:add']"
                    >新增</el-button>
                    <el-button
                            v-if="scope.row.parentId !== 0"
                            size="mini"
                            type="text"
                            icon="el-icon-delete"
                            @click="handleDelete(scope.row)"
                            v-hasPermi="['system:dept:remove']"
                    >删除</el-button>
                </template>
            </el-table-column>
        </el-table>

        <!-- 添加或修改部门对话框 -->
        <el-dialog :title="title" :visible.sync="open" width="500px" append-to-body>
            <el-form ref="form" :model="form" :rules="rules" label-width="80px" style="width: 100%;height: 100%">
                <el-form-item label="上级部门" prop="parentId" v-if="form.parentId !== 0">
                    <treeselect v-model="form.parentId" :options="deptOptions" :normalizer="normalizer" placeholder="选择上级部门" />
                </el-form-item>
                <el-form-item label="部门名称" prop="deptName">
                    <el-input v-model="form.deptName" placeholder="请输入部门名称" maxlength="20" autocomplete="off" />
                </el-form-item>
                <el-form-item label="显示排序" prop="orderNum" >
                    <el-input-number v-model="form.orderNum" controls-position="right" :min="0" autocomplete="off"  style="width: 100%;"/>
                </el-form-item>
                <el-form-item label="负责人" prop="leader">
                    <el-input v-model="form.leader" placeholder="请输入负责人" maxlength="20" autocomplete="off" />
                </el-form-item>
                <el-form-item label="部门状态" label-width="80px" prop="status" autocomplete="off">
                    <el-radio-group v-model="form.status" >
                        <el-radio :label="0">正常</el-radio>
                        <el-radio :label="1">停用</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitForm">确 定</el-button>
                <el-button @click="cancel">取 消</el-button>
            </div>

        </el-dialog>
    </div>
</template>

<script>
    import {deptLists,selectDeptById,updateDept,insertDept,deleteDept} from '@/api/system/dept.js'
    // import the component
    import Treeselect from '@riophae/vue-treeselect'
    // import the styles
    import '@riophae/vue-treeselect/dist/vue-treeselect.css'
    export default {
        components: { Treeselect },
        name: "dept",
        data() {
            return {
                // 遮罩层
                loading: true,
                // 显示搜索条件
                showSearch: true,
                // 表格树数据
                deptList: [],
                // 部门树选项
                deptOptions: [],
                // 弹出层标题
                title: "",
                // 是否显示弹出层
                open: false,
                // 是否展开，默认全部展开
                isExpandAll: true,
                labelPosition: 'right',
                // 重新渲染表格状态
                refreshTable: true,
                formLabelWidth: '120px',
                // 查询参数
                queryParams: {
                    deptName: undefined,
                    status: undefined
                },
                // 表单参数
                form: {
                    deptId: '',
                    parentId: '',
                    deptName: '',
                    orderNum: '',
                    leader: '',
                    status: 0
                },

                options: [{value: '正常', id: 0},{value: '停用', id: 1}],
                // treeData: "",
                // treeDataValue: "",
                // checkedKeys: '',
                // defaultProps: {
                //     children: "children",
                //     label: "deptName",
                // },
                // 表单校验
                rules: {
                    parentId: [
                        { required: true, message: "上级部门不能为空", trigger: "blur" }
                    ],
                    deptName: [
                        { required: true, message: "部门名称不能为空", trigger: "blur" }
                    ],
                    orderNum: [
                        { required: true, message: "显示排序不能为空", trigger: "blur" }
                    ],
                    email: [
                        {
                            type: "email",
                            message: "'请输入正确的邮箱地址",
                            trigger: ["blur", "change"]
                        }
                    ],
                    phone: [
                        {
                            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
                            message: "请输入正确的手机号码",
                            trigger: "blur"
                        }
                    ]
                }
            }
        },
        created(){
            this.deptLists()
        },
        methods: {
            normalizer(node) {
                if (node.children && !node.children.length) {
                    delete node.children;
                }
                return {
                    id: node.deptId,
                    label: node.deptName,
                    children: node.children
                };
            },
            // 表单重置
            reset() {
                this.form ={
                    deptId: undefined,
                    parentId: undefined,
                    deptName: undefined,
                    orderNum: undefined,
                    leader: undefined,
                    status: 0
                }
                this.resetForm("form");
                this.deptOptions = [];
            },
            /** 搜索按钮操作 */
            handleQuery() {
                this.deptLists();
            },
            /** 重置按钮操作 */
            resetQuery() {
                this.resetForm("queryForm");
                this.handleQuery();
            },
            // handClick(data) {
            //     console.log(data);
            //     this.form.parentId = data.deptId;
            //     this.treeData= data.deptName;
            // },
            submitForm(){
                this.$refs['form'].validate(valid => {
                    if (valid){
                        const dept = this.form
                        if (dept.deptId !== undefined){
                            updateDept(dept).then(res => {
                                this.$message({
                                    message: '修改成功',
                                    type: 'success',
                                });
                                this.open = false;
                                this.deptLists();
                            })
                        }else {
                            insertDept(dept).then(res => {
                                this.$message({
                                    message: "添加成功",
                                    type: 'success'
                                });
                                this.open = false;
                                this.deptLists();
                            })
                        }
                    }
                })
            },
            cancel() {
                this.open = false;
            },
            /** 展开/折叠操作 */
            toggleExpandAll() {
                this.refreshTable = false;
                this.isExpandAll = !this.isExpandAll;
                this.$nextTick(() => {
                    this.refreshTable = true;
                });
            },
            deptLists() {
                deptLists(this.queryParams).then(res => {
                    this.deptList = this.handleTree(res.data, 'deptId');
                })
            },
            handleUpdate(data){
                this.reset();
                this.open =true;
                this.title = '修改部门'
                deptLists().then(res => {
                    this.deptOptions = this.handleTree(res.data, 'deptId');
                });
                const deptId = data.deptId;
                selectDeptById({deptId}).then(res => {
                    this.form = res.data;
                    // console.log(this.form);
                });

            },
            handleAdd(data) {
                this.reset();
                if (data !== undefined){
                    this.form.parentId = data.deptId;
                }
                this.open = true;
                this.title = "添加部门";
                deptLists().then(res => {
                    this.deptOptions = this.handleTree(res.data, 'deptId');
                })
            },
            handleDelete(data) {
                this.$confirm('是否确认删除名称为"' + data.deptName + '"的数据项？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    const deptId = data.deptId
                    deleteDept({deptId}).then(res => {
                        if (res.status === 200 ){
                            this.deptLists();
                            this.$message({
                                type: 'success',
                                message: '删除成功!'
                            });
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            }
        }
    }

</script>

<style scoped>
    /*main-container全局样式*/
    .app-container {
        padding: 20px;
    }
    .mb8 {
        margin: 0 -5px 8px;
    }
    .el-dialog__body {
        padding: 5px 20px !important;
    }
</style>