import request from '@/utils/request.js';

export function deptLists(data) {
    return request({
        url: 'dept/selectDeptList',
        method: 'get',
        params: data
    })
}

export function selectDeptById(deptId) {
    return request({
        url: 'dept/selectDeptById',
        method: 'get',
        params: deptId
    })
}

export function updateDept(dept) {
    return request({
        url: 'dept/updateDeptById',
        method: 'put',
        data: dept
    })
}
export function insertDept(dept) {
    return request({
        url: 'dept/insertDept',
        method: 'post',
        data: dept
    })
}

export function deleteDept(deptId) {
    return request({
        url: 'dept/deleteDeptById',
        method: 'delete',
        data: deptId
    })
}
